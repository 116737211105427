import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/authenticationSlice';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({ email: '', password: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await dispatch(login(formData));
            if (response.error && response.error.message) {
                setErrorMessage(response.error.message);
            } else {
                navigate('/');
            }
        } catch (error) {
            console.error(error);
            // setErrorMessage('Login failed');
        }
    };

  return (
    <div className="container-fluid">
        <div className="row mt-5 pt-5 justify-content-center">
            <div className="col-lg-6">
                {errorMessage && <div className="text-danger">{errorMessage}</div>}
                <h2 className="text-center">{t('login.login')}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">{t('login.email')}</label>
                        <input
                            className="form-control"
                            type="email"
                            id="email"
                            name="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="password">{t('login.password')}</label>
                        <input
                            className="form-control"
                            type="password"
                            id="password"
                            name="password"
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button className="btn btn-primary w-100 mb-3" type="submit">{t('login.login')}</button>
                </form>
                <p>
                    {t('login.noAccount')} <Link to="/pre-registration">{t('registration.register')}</Link>
                </p>
            </div>
        </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchOrdersByUserId } from '../../redux/slices/ordersByUserIdSlice';
import { updateCancelAtStripeSubscription } from '../../redux/slices/updateCancelAtStripeSubscriptionSlice';
import { createStripeBillingPortal } from '../../redux/slices/createStripeBillingPortalSlice';
import { useTranslation } from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';

function SponsorDashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.user.id;

    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const orders = useSelector((state) => state.ordersByUserId.orders);
    const orderStatus = useSelector((state) => state.ordersByUserId.status);
    const error = useSelector((state) => state.ordersByUserId.error);

    useEffect(() => {
        if (userId) {
            dispatch(fetchOrdersByUserId(userId));
        }
    }, [userId, dispatch]);

    const subscriptionsStatus = (order) => {
        if (order.cancel_at === null) {
            return 'active';
        } else if (new Date(order.cancel_at) > new Date()) {
            return `active until ${new Date(order.cancel_at).toLocaleDateString()}`;
        }
        return 'canceled';
    }
    

    if (orderStatus === 'loading') {
        return <div>Loading...</div>;
    }

    if (orderStatus === 'failed') {
        return <div>Error: {error}</div>;
    }

    const handleCancelPlan = (order) => {
        setSelectedOrder(order);
        setShowCancelConfirmation(true);
    }

    const confirmCancelPlan = () => {
        if (selectedOrder) {
            const { order_id, stripe_subscription_id } = selectedOrder;
            dispatch(updateCancelAtStripeSubscription({ orderId: order_id, stripeSubscriptionId: stripe_subscription_id }));
            setShowCancelConfirmation(false);
        }
    };

    const cancelCancelPlan = () => {
        setShowCancelConfirmation(false);
        setSelectedOrder(null);
    };

    const handleStripeBillingPortal = () => {
        dispatch(createStripeBillingPortal(userId)).then((result) => {
            if (result.payload) {
                window.location.href = result.payload;
            }
        });
    }

    return (
        <div className="container mt-5 pt-5">
            <h2 className="">{t('plans.mySubscriptions')}</h2>
            <hr className="mb-3"/>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('grantees.grantee')}</th>
                        <th>{t('projects.projects')}</th>
                        <th>{t('plans.plan')}</th>
                        <th>{t('plans.price')}</th>
                        <th>{t('plans.status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.length > 0 && orders?.map((order) => (
                        <tr key={order.order_id}>
                            <td>{order.grantee_name}</td>
                            <td>{order.project_name}</td>
                            <td>{order.plan}</td>
                            <td>€{order.price}/mo</td>
                            <td>{subscriptionsStatus(order)}</td>
                            <td>
                                {order.cancel_at ? (
                                    ''
                                ) : (
                                    <button onClick={() => handleCancelPlan(order)} className="btn btn-primary">
                                        {t('buttons.cancel')}
                                    </button>
                                )}
                            </td>                         
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={handleStripeBillingPortal} className="btn btn-primary mt-3">
                {t('buttons.manageYourBillingInformation')}
            </button>
            <Modal show={showCancelConfirmation} onHide={cancelCancelPlan}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('plans.planCancellation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('plans.cancelPlan')}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelCancelPlan}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={confirmCancelPlan}>Confirm</button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default SponsorDashboard;
  
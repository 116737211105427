import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for creating project
export const createProject = createAsyncThunk('project/createProject', async (formData) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.user.id;

  let response;
  try {
    response = await fetch(`/api/projects/user/${userId}`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Project creation failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createProject thunk', error);
    throw new Error(error.message || 'An error occurred during the API call');
  }
});

const createProjectSlice = createSlice({
  name: 'project',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProject.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.project = action.payload;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default createProjectSlice.reducer;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {useDispatch } from 'react-redux'; 
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Projects from './components/projects/Projects';
import ProjectPage from './components/projects/ProjectPage';
import CreateProject from './components/projects/CreateProject';
import CreatePlan from './components/plans/CreatePlan';
import Sponsors from './components/sponsors/Sponsors';
import SponsorPage from './components/sponsors/SponsorPage';
import SponsorDashboard from './components/sponsors/SponsorDashboard';
import PreRegistration from './components/registration/PreRegistration';
import SponsorRegistration from './components/registration/SponsorRegistration';
import GranteeRegistration from './components/registration/GranteeRegistration';
import RegistrationSuccessPage from './components/registration/RegistrationSuccessPage';
import EmailVerificationSuccessPage from './components/registration/EmailVerificationSuccessPage';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import { loginSuccess } from './redux/slices/authenticationSlice';
import PaymentFlow from './components/payment/PaymentFlow';
import QrCodeGenerator from './components/qrcodes/QrCodeGenerator';
import QrCodes from './components/qrcodes/QrCodes';
import QrCodeScanner from './components/qrcodes/QrCodeScanner';
import CreateVoucher from './components/vouchers/CreateVoucher';
import Vouchers from './components/vouchers/Vouchers';
import Voucher from './components/vouchers/Voucher';
import ApproveVoucherRedemption from './components/vouchers/ApproveVoucherRedemption';


function App() {
  //  Store isLoggedIn to maintain the persistence of the login session
  const dispatch = useDispatch();

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      dispatch(loginSuccess()); // Dispatch a login success action to set isLoggedIn to true
    }
  }, [dispatch]);
  
  return (
    <Router>
			<Navbar />
					<Routes>
						<Route exact path="/" element={<Projects />} />
						<Route path="/projects/:projectId" element={<ProjectPage />} />
						<Route path="/create-project" element={<CreateProject />} />
						<Route path="/create-plan" element={<CreatePlan />} />
						<Route exact path="/sponsors" element={<Sponsors />} />
						<Route path="/sponsors/:sponsorId" element={<SponsorPage />} />
						<Route path="/sponsor-dashboard/" element={<SponsorDashboard />} />
						<Route path="/pre-registration" element={<PreRegistration />} />
						<Route path="/sponsor-registration" element={<SponsorRegistration />} />
						<Route path="/grantee-registration" element={<GranteeRegistration />} />
						<Route path="/registration-success" element={<RegistrationSuccessPage />} />
						<Route path="/email-verification-success" element={<EmailVerificationSuccessPage />} />
						<Route path="/login" element={<Login />} />
						<Route path="/logout" element={<Logout />} />
						<Route path="/payment-flow" element={<PaymentFlow />} />
						<Route path="/generate-qrcode" element={<QrCodeGenerator />} />
						<Route path="/qrcodes" element={<QrCodes />} />
						<Route path="/qrcode-scanner" element={<QrCodeScanner />} />
						<Route path="/create-voucher" element={<CreateVoucher />} />
						<Route path="/vouchers" element={<Vouchers />} />
						<Route path="/vouchers/:voucherId" element={<Voucher />} />
						<Route path="/vouchers/approve-redemption" element={<ApproveVoucherRedemption />} />
					</Routes>
			<Footer />
    </Router>
  );
}

export default App;



import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createVoucher } from '../../redux/slices/createVoucherSlice';
import { useTranslation } from 'react-i18next';


const CreateVoucher = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const createVoucherStatus = useSelector((state) => state.createVoucher.status);

    const [formData, setFormData] = useState({
        discount: '0',
        purchase_over: '0',
        valid_months: '0',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Dispatch action to create voucher
        dispatch(createVoucher(formData));
        setTimeout(() => {
            navigate('/vouchers');
        }, 1000); // Wait for 1 second before navigating to ensure create voucher completes
    }

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('vouchers.createVoucher')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 mt-5">
                            <label className="form-label" htmlFor="discount">{t('vouchers.discount')}</label>
                            <input
                                className="form-range"
                                type="range"
                                min="0"
                                max="100"
                                step="5"
                                id="discount"
                                name="discount"
                                autoComplete="discount"
                                value={formData.discount}
                                onChange={handleChange}
                                required
                            />
                            <span className="fs-3 fw-bold">{formData.discount}%</span>
                        </div>
                        <div className="mb-3 mt-5">
                            <label className="form-label" htmlFor="purchase-over">{t('vouchers.purchasesOver')}</label>
                            <input
                                className="form-range"
                                type="range"
                                min="0"
                                max="100"
                                step="5"
                                id="purchase-over"
                                name="purchase_over"
                                autoComplete="purchase_over"
                                value={formData.purchase_over}
                                onChange={handleChange}
                                required
                            />
                            <span className="fs-3 fw-bold">€{formData.purchase_over}</span>
                        </div>
                        <div className="mb-3 mt-5">
                            <label className="form-label" htmlFor="valid-months">{t('vouchers.validMonths')}</label>
                            <input
                                className="form-range"
                                type="range"
                                min="0"
                                max="24"
                                step="1"
                                id="valid-months"
                                name="valid_months"
                                autoComplete="valid_months"
                                value={formData.valid_months}
                                onChange={handleChange}
                                required
                            />
                            <span className="fs-3 fw-bold">{formData.valid_months}</span>
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-primary w-100" disabled={createVoucherStatus === 'loading'}>
                                {createVoucherStatus === 'loading' ? 'Creating Project...' : t('buttons.submit')}
                            </button>
                        </div>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default CreateVoucher;
function validateUsername(username) {
    const minLength = 3;
    const maxLength = 20;
    const allowedCharacters = /^[a-zA-Z0-9_-]+$/;
    const reservedWords = ['admin', 'root', 'superuser'];

    // Convert username to lowercase
    username = username.toLowerCase();
    
    // Check length
    if (username.length < minLength || username.length > maxLength) {
        return false;
    }
    
    // Check character set
    if (!allowedCharacters.test(username)) {
        return false;
    }
    
    // Check reserved words
    if (reservedWords.includes(username)) {
        return false;
    }
    
    // Add more validation rules as needed
    
    return true;
}

export default validateUsername;